import { getLoggedUser } from './services/users/api'

let loggedUser = {}

const resetLoggedUser = () => {
  loggedUser = {}
}

const resetUserInLocalStorage = () => {
  localStorage.removeItem('isUserLogged')
  localStorage.removeItem('auth_token')
}

const loadLoggedUser = async () => {
  resetLoggedUser()

  try {
    const userToken = localStorage.getItem('auth_token')

    if (userToken) {
      loggedUser = await getLoggedUser()
    } else {
      resetUserInLocalStorage()
    }
  } catch (e) {
    resetUserInLocalStorage()
  }
}
export default async () => {
  await loadLoggedUser()
}

export {
  loggedUser,
  loadLoggedUser,
  resetLoggedUser,
  resetUserInLocalStorage
}
