import debounce from "./debounce";
import is from "./is"
import { getStorage } from './web-storage'
import redirectV1 from './redirect-v1'

const LocalStorage = getStorage('local')
const SessionStorage = getStorage('session')

export {
  debounce,
  is,
  LocalStorage,
  SessionStorage,
  redirectV1
}
