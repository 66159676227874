// import store from "@/state/store";
import professions from './routes/professions'
import publications from './routes/publications'

export default [
  {
    path: '/',
    name: 'default',
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index2.vue'),
      },
      ...professions,
      ...publications,
      {
        path: 'switch-from-v1',
        name: 'switch_from_v1',
        // component: () => import('@/views/contacts/index.vue'),
        redirect: to => {
          const { token } = to.query
          console.log(token)
        }
      }
    ]
  }
];
