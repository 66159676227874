import axios from 'axios'
import { LocalStorage, redirectV1 } from '@/utils'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const onResponseSuccess = (response) => {
  return response
}

const onResponseError = error => {
  const { status } = error.response

  if (status === 401) {
      return redirectV1('/login')
  }

  return Promise.reject(error)
}

instance.interceptors.request.use(
  function (config) {
    config.headers.Accept = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    const token = LocalStorage.getItem('auth_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  onResponseSuccess,
  onResponseError
)

export {
  instance as axios,
  // VueAxios
}
