import { axios } from '@/axios'

export const getUsers = async () => {
    const { data } = await axios.get('/users/list')
    return data
  }
  
  export const getUserStatus = async () => {
    const { data } = await axios.get('/users/user-status')
    return data
  }

  export const getLoggedUser = async () => {
    const { data } = await axios.get('/users/logged-user')
    return data
  }